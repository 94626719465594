.logo img {
  width: 170px;
}

.sidebar .logo .logo-img {
  width: auto;
  height: auto;
  margin: auto;
  display: block;
}

.sidebar .logo a.logo-normal {
  padding: 0px;
}

.sidebar .logo, body > .navbar-collapse .logo {
  padding-top: 6.2685px;
  padding-bottom: 6.2685px;
}

#sidebar::after {
  opacity: 1.0;
  background: #ff5c0e;
}

.sidebar .sidebar-background {
  background-size: initial;
  background-position: initial;
}
.editable-table-label {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  text-transform: uppercase;
  font-weight: 400;
  color: #9A9A9A;
}

.login-card {
  margin-top: 30px;
  z-index: 4;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fadeinslidebottom 0.7s linear;
}

#login-form {
  margin-top: 5px;
}

#login-logo {
  width: 250px;
  display: block;
  margin: auto;
}

#login-container {
  background: #ff5c0e;
  height: 100vh;
  width: 100%;
  display: block;
}

.login-container-logged-in, .login-container-logged-in::before, .login-container-logged-in::after {
  animation: wipeleft 1s linear;
}

@-webkit-keyframes fadeinslidebottom {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  100% {
    opacity: 1;
    margin-top: 30px;
  }
}

@keyframes fadeinslidebottom {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  100% {
    opacity: 1;
    margin-top: 30px;
  }
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

.loader-circle {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loader-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-text {
  text-align: center;
  margin-top: 10px;
  font-size: 1.2em;
  color: #888;
}

.loader-fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 10;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.form-image {
  width: 25%;
}

.branch-logo {
  display: block;
  margin: auto;
  width: 100%;
}

.product-image, .merchant-logo {
  height: 40px;
}

.merchant-logo-drag {
  height: 80px
}

.merchant-name {
  padding-left: 20px;
}

.product-image-zoomed, .logo-zoomed {
  height: 200px;
}

.no-field-text, .not-found-text {
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  color: #888;
}

.custom-tooltip.tooltip>.tooltip-inner {
  font-size: 1.2em;
  background-color: #FFFFFF;
  color: #374D40;
  padding: 0px;
  border: none;
  max-width: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.custom-tooltip.tooltip.top>.tooltip-arrow,
.custom-tooltip.tooltip.bottom>.tooltip-arrow,
.custom-tooltip.tooltip.left>.tooltip-arrow,
.custom-tooltip.tooltip.right>.tooltip-arrow {
  display: none;
}

.table-action-button, .multi-button {
  margin-right: 5px;
}

.action-button {
  padding: 8px 8px;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button, 
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-clear-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.tooltip.in {
  opacity: 1;
}

th {
  white-space: nowrap;
}

.table > tbody > tr > td {
  padding: 7px !important;
}

.description-cell {
  width: 20%;
}

.card-button {
  margin-left: 5px;
}

.time-input {
  width: 80px;
}

.date-input {
  width: auto;
  margin-right: 9.39px;
}

.datetime-input {
  display: inline-block;
}

.datetime-cell {
  white-space: nowrap;
}

.time-separator {
  margin-left: 3px;
  margin-right: 3px;
}

#products-table>tbody+tbody,
.listings-table>tbody+tbody,
.transactions-table>tbody+tbody,
.merchants-table>tbody+tbody,
.settings-table>tbody+tbody,
.accounts-table>tbody+tbody,
.emails-table>tbody+tbody,
.orders-table>tbody+tbody,
.promocodes-table>tbody+tbody {
  border-top: none;
}

.is-editing-row {
  background-color: #f5f5f5;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes wipeleft {
    0% { width: 100%; }
    100% { width: 0% }
}

@-webkit-keyframes wipeleft {
    0% { width: 100%; }
    100% { width: 0% }
}

#bulk-upload-button-group {
  margin-bottom: 5px;
}

.table-responsive {
  overflow: auto;
}

.label-block {
  display: block;
}

.month-select, .year-select {
  width: 100px;
  display: inline-block;
  margin-left: 9.39px;
}

@media (max-width: 768px) {
  .time-label {
    margin-top: 15px;
  }
}

.DayPickerInput-Overlay {
  top: -255.4px;
}

.text-formatted {
  white-space: pre-wrap;
}

.sidebar-icon {
  font-size: 20px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center; 
}

.qr-code {
  display: block;
  margin: auto;
}

.qr-code-responsive {
  width: 100%;
}

@media (min-width: 576px) {
  .qr-code-responsive {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .qr-code-responsive {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .qr-code-responsive {
    width: 50%;
  }
}

.select-content {
  margin-top: 20px;
}

.branch-card {
  transition: box-shadow 0.2s;
}

.branch-card:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card form [class*="col-"].branch-product-image-col {
  padding-left: 0px;
}

.branch-product-image-row {
  padding-left: 15px;
  padding-right: 15px;
}

.branch-product-image {
  height: 100px;
}

.carousel-control.left {
  background-image: none;
}

.carousel-control.right {
  background-image: none;
}

.carousel-indicators li {
  bottom: 0px;
  border-width: 1px;
  border-color: #ff5c0e;
}

.carousel-indicators .active {
  background-color: #ff5c0e;
}

.save-positions-button {
  margin-top: 10px;
}

.merchant-position {
  margin-left: 25px;
  margin-right: 25px;
  font-size: 30px;
  vertical-align: middle;
  color: grey;
}

input[type=checkbox].admin-checkbox,
input[type=checkbox].all-checkbox,
input[type=checkbox].promocode-checkbox {
  vertical-align: middle;
  margin: 0px 0px 0px;
}

.admin-label, .all-label, .promocode-label {
  vertical-align: middle;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 400;
  color: #9A9A9A;
  margin-left: 5px;
  margin-right: 5px;
}

.description-cell, .disclaimer-cell, .instructions-cell {
  max-width: 20%;
}
